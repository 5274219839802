<template>
  <v-card height="100%" tile class="pa-10">
    <v-tabs align-with-title>
      <v-tabs-slider color="primary" />
      <v-tab exact @click="$router.push('/reportes/socios-activos')">
        Socios Activos
      </v-tab>
      <v-tab exact @click="$router.push('/reportes/socios-nuevos')">
        Socios Nuevos
      </v-tab>
      <v-tab exact @click="$router.push('/reportes/invitados')">
        Invitados
      </v-tab>
    </v-tabs>


    <router-view></router-view>
  </v-card>
</template>

<script>
export default {
  name: "reports",
  methods: {
    // setDates() {
    //   this.$router.push(`${this.$route.path}?start_date=${this.datei[0]}&end_date=${this.datei[1]}`)
    // },
    // setDatesClear(){
    //     this.$router.push(`${this.$route.path}`)
    // },
    // goToRoute(route){
    //   if(this.datei){
    //     this.$router.push(`${route}?start_date=${this.datei[0]}&end_date=${this.datei[1]}`)
    //   }else{
    //     this.$router.push(`${route}`)
    //   }
    // }
  },
  data: () => ({
    // datei: null,
    // menu: null
  }),

};
</script>

<style>
</style>